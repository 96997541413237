<template>
    <v-app>
      <navbar @goTo="scrollTo" :key="reloadnav" @switchLocale="switchLocale"></navbar>
    
      <v-main class="mt-12">
        <Content/>
      </v-main>
      <Footer />
    </v-app>
  </template>
  
  <script>
  import Navbar from "@/components/Navbar";
  import Footer from '@/views/Footer';
  import Content from '@/components/Content'
  
  export default {
    name: "App",
    components: {
      Navbar,
      Content,
      Footer
    },
    data() {
      return {
        reloadnav: 0,
        locales: process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(','),
        window: {
            width: 0,
            height: 0
        },
        valid: true,
      };
    },
    beforeMount(){
        if(this.$cookies.get('loggedIn') != 'true')
            this.$router.push('/')
    },
    methods:{
      scrollTo(el){
        document.getElementById(el).scrollIntoView({ behavior: "smooth", block: "end" });
      },  
      switchLocale(l){
          this.$i18n.locale = l
          this.reloadnav++
      },
      chooseLanguage(){
          if(this.$i18n.locale == 'pt')
              return 'gb';

          return 'pt'
      },
    }
  };
  </script>
  
  
  
  
  
<template>
    <v-footer
      padless
    >
      <v-card
        class="flex"
        flat 
        color="#EEE4CB"
        tile
      >
        <v-divider></v-divider>
  
        <v-card-text class="py-2 text-center">
          <div style="color: #755430;">
            {{ new Date().getFullYear() }} — <strong>{{ $t('developed_by') }} Dreamdeal Technologies</strong>
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
  </template>
  <script>
  export default {
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      social_networks:[
        {
            icon: 'mdi-linkedin',
            url:  'https://www.linkedin.com/company/dreamdeal-technologies/'
        }
      ]
    }),
    methods:{
        goTo(social){
            window.open(social.url)
        }
    }
  }
</script>
<template>
    <v-container fluid>
        <div class="myparallax"></div>
        <div class="myparallax"></div>
        <v-container fluid id="homepage">
        <v-flex 2>
            <v-card elevation="0" class="mx-auto" flat max-width="1200px"
                >
            <v-card-text
                class="text-center goldie--text"
            >
            
            <div class="card bg-brownie">
            <div class="blob-header"></div>
                <v-col cols="12">
                    <p class="mt-8" style="font-size: 16px; text-align: justify; white-space: pre-line;">
                        <v-expansion-panels flat>
                            <v-expansion-panel class="transparent"  expand pa-0>
                                <v-expansion-panel-header class="text-center">
                                    <v-row>
                                        <v-col cols="12">
                                            <span class="goldie--text"><h2>{{ $t('INTRO.TITLE') }}</h2></span>
                                        </v-col>
                                        <v-col cols="12">
                                            <span class="goldie--text text-body-1">{{ $t('INTRO.SUBTITLE') }}</span>
                                        </v-col>
                                    </v-row>
                                    <template v-slot:actions>
                                        <v-icon class="goldie--text">
                                        $expand
                                        </v-icon>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content  class="goldie--text text-justify">
                                    <v-row>
                                        <v-col cols="12" v-html="$t('INTRO.INTRO_TEXT')"></v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        
                    </p>
                    
                </v-col>
            </div>
                
            
            </v-card-text>            
            </v-card>
        </v-flex>
        </v-container>

        <div class="myparallax"></div>

        <v-container fluid id="pray">
        <v-flex 2>
            <v-card elevation="1" class="mx-auto" flat max-width="800px">
            <v-toolbar flat color="#AA6B30">
                <v-card-title class="brownie--text">
                {{ $t('MEDITATION') }}
                </v-card-title>
            </v-toolbar>
            <center>
                <v-img contain src="@/assets/icons/PNG/01-Meditation-Girl.png" height="150" class="mt-6 mb-4"></v-img>
            </center>
            <v-card-text
                class="text-center"
            >
            
                        
                        <!-- GRATIDAO -->
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <strong><span>{{ $t('ORACAO.TITLE') }}</span></strong>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="color: #00368C;white-space: pre-line; font-family: Mistral; font-size: 35px;">                                                                                 
                                    <div class="mt-6">{{ $t('ORACAO.TEXT') }}</div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                   

                        <!-- Meditação da sincronização -->
                       
                            <v-expansion-panel expand pa-0>
                                <v-expansion-panel-header>
                                    <strong><span>{{ $t('NAO_INTERESSA.TITLE') }}</span></Strong>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="color: #00368C;white-space: pre-line; font-family: Mistral; font-size: 35px;">                                                                                 
                                    <div class="mt-6" v-html="$t('NAO_INTERESSA.TEXT')"></div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>


                   
            
            </v-card-text>            
            </v-card>
        </v-flex>
        </v-container>

        <div class="myparallax" v-if="false"></div>

        <v-container fluid id="pray" v-if="false">
        <v-flex 2>
            <v-card elevation="1" class="mx-auto" flat max-width="800px">
            <v-toolbar flat color="#AA6B30">
                <v-card-title class="brownie--text">
                {{ $t('NAO_INTERESSA.TITLE') }}
                </v-card-title>
            </v-toolbar>
            <center>
                <img v-if="false" class="ma-5 pointer" src="https://cdn-icons-png.flaticon.com/512/4785/4785367.png" height="150">
            </center>
            <v-card-text
                class="text-center"
            >
                
            <v-col v-html="$t('NAO_INTERESSA.TEXT')" cols="12" class="mt-n2 text-left" style="color: #00368C;white-space: pre-line; font-size: 18px;">
                
            </v-col>
            </v-card-text>            
            </v-card>
        </v-flex>
        </v-container>

        <div class="myparallax"></div>

        <v-container fluid id="steps">
        <v-flex 2>
            <v-card elevation="1" class="mx-auto" flat max-width="800px">
            <v-toolbar flat color="#AA6B30">
                <v-card-title class="brownie--text">
                {{ $t("PASSOS.TITLE") }}
                </v-card-title>
            </v-toolbar>
            <center>
                <v-img contain src="@/assets/icons/PNG/02-Steps.png" height="150" class="mt-6 mb-4"></v-img>
            </center>
            <v-card-text
                class="text-center"
            >
                <v-expansion-panels >
                    <v-expansion-panel :key="index" v-for="(passo, index) in $t('PASSOS.TEXT')">
                        <v-expansion-panel-header v-if="passo.TITLE != ''">
                            <p><strong>{{passo.TITLE}}</strong> {{processDates(passo)}}</p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content v-if="passo.TITLE != ''" style="white-space: pre-line;">
                            <v-divider></v-divider>
                            <v-expansion-panels class="mt-4" popout v-if="passo.SUB_CATEGORIES">
                                <v-expansion-panel :key="index2" v-for="(sCat, index2) in passo.SUB_CATEGORIES_DESC">
                                    <v-expansion-panel-header><p><strong>{{ sCat }}</strong> {{processArrayDates(passo, index2)}}</p></v-expansion-panel-header>
                                    <v-expansion-panel-content style="white-space: pre-line;">
                                        <pdf 
                                            v-for="i in passo.PAGES[index2]" :key="i" :page="i"  :src="passo.PDF[index2]">
                                        </pdf>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            
                            <pdf v-else 
                                v-for="i in passo.PAGES" :key="i" :page="i" :src="passo.PDF">
                            </pdf>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>
            </v-card-text>            
            </v-card>
        </v-flex>
        </v-container>


        <div class="myparallax"></div>

        <v-container fluid id="doubts">
        <v-flex 2>
            <v-card elevation="1" class="mx-auto" flat max-width="800px">
            <v-toolbar flat color="#AA6B30">
                <v-card-title class="brownie--text">
                {{ $t('DOUBTS.TITLE') }}
                </v-card-title>
            </v-toolbar>
            <center>
                <v-img contain src="@/assets/icons/PNG/03-Questions.png" height="150" class="mt-6"></v-img>
            </center>
            <v-card-text
                class="text-center"
            >
                <v-container>
                    <v-card elevation="0" color="transparent" class="card-opacity">
                        <v-card-text class="text-blue text-center">            
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                            >
                                <v-text-field
                                    v-model="question.name"
                                    :label="$t('FIELDS.NAME')"
                                    required
                                ></v-text-field>

                                <v-text-field
                                v-model="question.phone"
                                :label="$t('FIELDS.TELEFONE')"
                                required
                                ></v-text-field>

                                <v-text-field
                                v-model="question.email"
                                :rules="emailRules"
                                :label="$t('FIELDS.EMAIL')"
                                required
                                ></v-text-field>

                                <v-textarea
                                    name="input-7-1"
                                    v-model="question.description"
                                    :label="$t('FIELDS.DUVIDA')"
                                    hint="Descreva a sua dúvida"
                                ></v-textarea>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn block x-large depressed color="#F7AE72" @click="duvidas" :disabled="lockButtonDoubts" class="white--text">
                {{$t('FIELDS.ENVIAR')}} {{ lockButtonDoubts == true && countDown != 10 ?  '(' + countDown + ')' : '' }}
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-flex>
        </v-container>

        <div class="myparallax"></div>

        <v-container fluid id="subscriptions">
            <v-flex 2>
                <v-card elevation="1" class="mx-auto" flat max-width="800px">
                <v-toolbar flat color="#AA6B30">
                    <v-card-title class="brownie--text">
                    {{ $t('INSCRICOES.TITLE') }}
                    </v-card-title>
                </v-toolbar>
                <center>
                    <v-img contain src="@/assets/icons/PNG/04-Form.png" height="150" class="mt-6"></v-img>
                </center>
                <v-card-text class="text-center">
                    <v-container style="width: 100%;">
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-text-field
                                v-model="subscription.name"
                                :label="$t('FIELDS.NAME')"
                                required
                            ></v-text-field>

                            <v-text-field
                                v-model="subscription.telephone"
                                :label="$t('FIELDS.TELEFONE')"
                                required
                            ></v-text-field>

                            <v-text-field
                            v-model="subscription.email"
                            :rules="emailRules"
                            :label="$t('FIELDS.EMAIL')"
                            required
                            ></v-text-field>

                            <v-select
                                v-model="subscription.steps"
                                :items="getSteps"
                                attach
                                chips
                                :label="$t('FIELDS.PASSOS')"
                                multiple
                                :hint="$t('FIELDS.PASSOS_HINT')"
                                persistent-hint
                            ></v-select>

                            <v-textarea
                            v-model="subscription.description"
                            :label="$t('FIELDS.DESCRICAO')"
                            required
                            ></v-textarea>
                            
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn block x-large depressed color="#F7AE72" class="white--text" @click="inscrever" :disabled="lockButtonSubscription">
                    Inscrever {{ lockButtonSubscription == true && countDown != 10 ?  '(' + countDown + ')' : '' }}
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-flex>
        </v-container>

        <div class="myparallax"></div>
        <div class="myparallax">
            <Slider />
        </div>

        

            

        <ErrorSnackbar v-model="error.show" :message="error.msg" style="z-index: 9999 !important;"/>
        <SuccessSnackBar v-model="success" message="Email enviado com sucesso"/>
    </v-container>
</template>
<script>
import ErrorSnackbar from "@/components/ui/ErrorSnackbar"
import SuccessSnackBar from "@/components/ui/SuccessSnackBar"
import Slider from '@/components/ui/testimonials/Slider'
import pdf from 'vue-pdf'
import * as FormData from 'form-data'; 

import Mailgun from 'mailgun.js';  


export default {
    name: "App",
    components: {
        pdf,
        ErrorSnackbar,
        SuccessSnackBar,
        Slider
    },
    data() {
      return {
        countDown: 10,
        lockButtonDoubts: false,
        lockButtonSubscription: false,
        success: false,
        error:{
            show: false,
            msg: ''
        },
        onboarding: 0,
        reloadnav: 0,
        locales: process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(','),
        window: {
            width: 0,
            height: 0
        },
        question:{
            name: '',
            phone: '',
            email: '',
            description: ''
        },
        subscription:{
            name: '',
            telephone: '',
            email: '',
            steps: [],
            description: ''
        },
        emailRules: [
            v => !!v || 'E-mail obrigatório',
            v => /.+@.+\..+/.test(v) || 'E-mail tem de ser válido',
        ],
        valid: true,
      };
    },
    beforeMount(){
        if(this.$cookies.get('loggedIn') != 'true')
            this.$router.push('/')
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    mounted(){
        this.isMobile()
        setInterval(this.next, 5000);
    },
    methods:{
        processArrayDates(passo, index){
            if(passo.DATAS_ARRAY == null)
                return ''
            
            if(passo.DATAS_ARRAY[index] == '')
                return ''

            return '(' + passo.DATAS_ARRAY[index] + ')';
        },  
        processDates(passo){
            if(passo.DATAS == "")
                return "";
                
            return '(' + passo.DATAS + ')';
        },
        countDownTimer () {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }else{
                this.lockButtonDoubts = false
                this.lockButtonSubscription = false
                this.countDown = 10
            }
        },
        next () {
            this.onboarding = this.onboarding + 1 === this.$t('TESTIMONIALS.TESTIMONIALS').length
            ? 0
            : this.onboarding + 1
        },
        prev () {
            this.onboarding = this.onboarding - 1 < 0
            ? this.$t('TESTIMONIALS.TESTIMONIALS').length - 1
            : this.onboarding - 1
        },
        inscrever(){ 
            this.lockButtonSubscription = true

            if(this.subscription.name == ''){
                this.error.msg = "Coloque o nome na subscrição";
                this.error.show = true;
                return
            }
            if(this.subscription.telephone == ''){
                this.error.msg = "Coloque o número telemóvel na subscrição";
                this.error.show = true;
                return
            }
            if(this.subscription.email == ''){
                this.error.msg = "Coloque o email na subscrição";
                this.error.show = true;
                return
            }

            if(this.subscription.steps.length == 0){
                this.error.msg = "Selecione passos na subscrição";
                this.error.show = true;
                return
            }
            this.success = true

            const mailgun = new Mailgun(FormData); 

            const mg = mailgun.client( 

                    { 

                        username: 'api',  

                        key: '7cd99b1d8469e35613685630190ce213-10eedde5-8c351df0', //'27d7d132f922d444cef9d1f0c0c38f13-c30053db-9af6b30e', 

                        url:"https://api.eu.mailgun.net" 

                    } 

                ); 

            let message =   "Nova inscrição!<br/><br/>" +
                            "Nome: " + this.subscription.name + "<br/><br/>" +
                            "Telefone: " + this.subscription.telephone + "<br/><br/>" +
                            "Email: " + this.subscription.email + "<br/><br/>" +
                            "Passos: " + this.subscription.steps + "<br/><br/>" + 
                            "Descrição: " + this.subscription.description + "<br/><br/>";

            mg.messages.create('email.dreamdealtech.com', { 

            from: "info@aldaafonso.com", 

            to: ['aldamafonso@hotmail.com'], //["aldamafonso@hotmail.com"], 

            subject: "Nova inscrição recebida", 

            text: "Nova inscrição", 

            html: message

            }) 

            .then(msg => console.log(msg)) // logs response data 

            .catch(err => console.error(err)); // logs any error 

            
            this.countDownTimer()

            this.subscription.name = ''
            this.subscription.telephone = ''
            this.subscription.email = ''
            this.subscription.steps = null
            this.subscription.description = ''

            this.success = true

        }, 
        duvidas(){
            this.lockButtonDoubts = true

            if(this.question.name == ''){
                this.error.msg = "Coloque o nome na sua dúvida";
                this.error.show = true;
                return
            }

            if(this.question.phone ==''){
                this.error.msg = "Coloque o contacto na sua dúvida";
                this.error.show = true;
                return
            }

            if(this.question.email == ''){
                this.error.msg = "Coloque o email na sua dúvida";
                this.error.show = true;
                return
            }

            if(this.question.description ==''){
                this.error.msg = "Coloque uma dúvida. O campo está vazio";
                this.error.show = true;
                return
            }

            this.success = true
            const mailgun = new Mailgun(FormData); 

            const mg = mailgun.client( 

                    { 

                        username: 'api',  

                        key: '7cd99b1d8469e35613685630190ce213-10eedde5-8c351df0', //'27d7d132f922d444cef9d1f0c0c38f13-c30053db-9af6b30e', 

                        url:"https://api.eu.mailgun.net" 

                    } 

                ); 

            let message =   "Dúvidas!<br/><br/>" +
                            "Nome: " + this.question.name + "<br/><br/>" +
                            "Telefone: " + this.question.phone + "<br/><br/>" +
                            "Email: " + this.question.email + "<br/><br/>" +
                            "Dúvida: " + this.question.description + "<br/><br/>";

            mg.messages.create('email.dreamdealtech.com', { 

            from: "info@aldaafonso.com", 

            to: ['aldamafonso@hotmail.com'], //["aldamafonso@hotmail.com"], 

            subject: "Dúvidas", 

            text: "Dúvidas", 

            html: message

            }) 

            .then(msg => console.log(msg)) // logs response data 

            .catch(err => console.error(err)); // logs any error 
            

            this.countDownTimer()

            this.question.name = ''
            this.question.phone = ''
            this.question.email = ''
            this.question.description = ''

            this.success = true
        },
        isMobile() {
            let needReload = this.$cookies.get('needReload')
            
            if(needReload != 'true'){
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    this.$cookies.set("needReload", true,"2h");
                } else {
                    this.$cookies.set("needReload", false,"2h");
                }
            }else{
                this.$cookies.set("needReload", false,"2h");
            }

            if(needReload == 'true'){
                location.reload();
            }
        },
      scrollTo(el){
        document.getElementById(el).scrollIntoView({ behavior: "smooth", block: "end" });
      },  
      switchLocale(l){
          this.$i18n.locale = l
          this.reloadnav++
      },
      chooseLanguage(){
          if(this.$i18n.locale == 'pt')
              return 'gb';

          return 'pt'
      },
      handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
      },
    },
    computed:{  
        getHeight(){
            return this.window.height;
        },
        getSteps(){
          let steps = [];

          let helper = this.$t('PASSOS.TEXT')

          console.log(this.$t('PASSOS.TEXT'))

          helper.forEach(element => {
            steps.push(element.TITLE)
          });

          return steps
        }
    }
  };
</script>
<style>
    .brownie{
        background-color: #AA6B30 !important;
    }

    

  .myparallax {
      /* The image used */
      background-image: url("../assets/logo/LOGO JANELA-01.png");
      /* Set a specific height */
      min-height: 200px; 
      height: 100%;

      /* Create the parallax scrolling effect */
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    @media (min-width: 700px) {
    .myparallax {
      /* The image used */
      background-image: url("../assets/logo/LOGO JANELA-01.png");
      /* Set a specific height */
      min-height: 350px; 
      height: 100%;

      /* Create the parallax scrolling effect */
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50% 100%;
    }
  }

  
  body #bg-div{
    
    background: rgb(255, 255, 255) url("../assets/logo/LOGO JANELA-01.png") fixed;

    background-position: center;

    background-size: contain;

    background-repeat: no-repeat;
}


  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    width: 100%;
  }

  .brownie--text{
    color: #EEE4CB !important;
  }

  .goldie--text{
    color: #AA6B30 !important;
  }


  .card {
    content: " ";
    display: block;
    border-radius: 26px;
    box-shadow: 0px 5px 31px 1px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  .blob-header {
    position: relative;
  }
  .blob-header::before {
    position: absolute;
    top: -60px;
    left: -40px;
    display: block;
    content: "";
    background-color: #EEE4CB;
    height: 98.86px;
    width: 105.81px;
    border-radius: 100%;
  }
  /* Colors */
  .bg-gold {
    background-color: #AA6B30;
  }

  .bg-brownie{
    background-color: #EEE4CB;
  }
  </style>
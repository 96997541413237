<template>
    <div id="cabecalho">
        <v-app-bar color="#EEE4CB" class="d-none d-lg-block elevation-10" fixed>
            
                <img class="pointer" :src="require('../assets/logo/LOGO AA-01.png')" height="70">
    
                <v-tabs right dark background-color="#EEE4CB" v-if="!login">
                    <v-tab style="color: #755430; font-weight: bold;" @click="$emit('goTo', 'homepage')">{{ $t('MENU.FIRST') }}</v-tab>
                    <v-tab style="color: #755430; font-weight: bold;" @click="$emit('goTo', 'pray')">{{ $t('MENU.SECOND') }}</v-tab>
                    <v-tab style="color: #755430; font-weight: bold;" @click="$emit('goTo', 'steps')">{{ $t('MENU.THIRD') }}</v-tab>
                    <v-tab style="color: #755430; font-weight: bold;" @click="$emit('goTo', 'doubts')">{{ $t('MENU.FOURTH') }}</v-tab>
                    <v-tab style="color: #755430; font-weight: bold;" @click="$emit('goTo', 'subscriptions')">{{ $t('MENU.FIFTH') }}</v-tab>
                </v-tabs>
                <v-spacer></v-spacer>
                <v-menu offset-y bottom transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="black"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            x-large
                        >
                            <flag :iso="chooseLanguage()" class="elevation-10"/>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item class="text-center">
                            <v-list-item-title style="cursor: pointer;" @click="switchLocale('pt')"><div style="width: 45px;"><flag :iso="'pt'"/></div></v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item class="text-center">
                            <v-list-item-title style="cursor: pointer;" @click="switchLocale('en')"><div style="width: 45px;"><flag :iso="'gb'"/></div></v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item class="text-center">
                            <v-list-item-title style="cursor: pointer;" @click="switchLocale('fr')"><div style="width: 45px;"><flag :iso="'fr'"/></div></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            
            
        </v-app-bar>

        <v-app-bar color="#EEE4CB" class="d-md-none elevation-10" fixed>
            <img class="pointer" :src="require('../assets/logo/LOGO AA-01.png')" height="50">
            <v-spacer></v-spacer>
            <v-menu offset-y bottom transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="black"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        x-large
                    >
                        <flag :iso="chooseLanguage()" class="elevation-10"/>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item class="text-center">
                        <v-list-item-title style="cursor: pointer;" @click="switchLocale('pt')"><div style="width: 45px;"><flag :iso="'pt'"/></div></v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item class="text-center">
                        <v-list-item-title style="cursor: pointer;" @click="switchLocale('en')"><div style="width: 45px;"><flag :iso="'gb'"/></div></v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item class="text-center">
                        <v-list-item-title style="cursor: pointer;" @click="switchLocale('fr')"><div style="width: 45px;"><flag :iso="'fr'"/></div></v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    v-bind="attrs"
                    v-on="on"
                    >
                    mdi-menu
                    </v-icon>
                </template>
                <v-list>
                    <v-list-item :key="1" @click="$emit('goTo', 'homepage')"
                    >
                        <v-list-item-title>Homepage</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item :key="2" @click="$emit('goTo', 'pray')"
                    >
                        <v-list-item-title>Oração</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item :key="3" @click="$emit('goTo', 'steps')"
                    >
                        <v-list-item-title>Passos</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item :key="4" @click="$emit('goTo', 'doubts')"
                    >
                        <v-list-item-title>Tem dúvidas?</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item :key="5" @click="$emit('goTo', 'subscriptions')"
                    >
                        <v-list-item-title>Inscrições</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

    
    </div>
  </template>
  
  
  <script>
export default{
    data(){
        return{
            locales: process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(',')
        }
    },
    props:{
        login:{
            typeof:Boolean,
            default: false
        }
    },
    methods:{
        switchLocale(l){
            this.$emit('switchLocale', l)
        },
        chooseLanguage(){
            return this.$i18n.locale == 'en' ? 'gb' : this.$i18n.locale
        }
    }
}
  </script>
  
  <style>
  .pointer {
    cursor: pointer;
  }

  #my-tabs .v-tab{
    font-weight: bold !important;
  }
  </style>
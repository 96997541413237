<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {
  },

  data: () => ({
  }),
};
</script>
<style>
@font-face {
      font-family: 'Mistral';
      src: local('Mistral'), url('./assets/fonts/mistral/MISTRAL.TTF')  format('truetype'), 
    }
</style>
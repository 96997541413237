<template>
    <v-app id="login-bg" style="background-color: transparent;">
        <v-main fluid>
            <navbar :login="true" @switchLocale="switchLocale"></navbar>
        </v-main>

        <v-container class="center" fluid>
            <v-card elevation="10" class="card-opacity">
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <h2>{{ $t('HOMEPAGE.SECRET') }}:</h2>
                        </v-col>
                        <v-col cols="12">
                            <v-otp-input
                                v-model="otp"
                                :length="length"
                                type="password"
                            ></v-otp-input>
                        </v-col>
                        <v-col cols="12" class="my-3">
                            <v-btn color="primary darken-4" block :disabled="!isActive" @click="checkSecretCode">{{ $t('LOGIN') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>

        
    </v-app>
</template>
<style>
.card-opacity{
    opacity: 0.9;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

 @media (min-width: 600px) {
    .center {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30%;
        transform: translate(-50%, -50%);
        padding: 10px;
    }
}

body #login-bg{
    
    background: rgb(255, 255, 255) url("../assets/logo/LOGO JANELA-01.png") fixed;

    background-position: center;

    background-size: contain;

    background-repeat: no-repeat;
}


</style>
<script>
import Navbar from "@/components/Navbar";

export default{
    components:{
        Navbar,
    },
    data(){
        return{
            locales: process.env.VUE_APP_I18N_FALLBACK_LOCALE.split(','),
            otp: '',
            length: 4,
        }
    },
    methods:{
        checkSecretCode(){
            let secretCode = "4444"
            if(this.otp == secretCode){
                this.$cookies.set("loggedIn",true,"2h");
                this.$router.push('/homepage')
            }
        },
        switchLocale(l){
          this.$i18n.locale = l
          this.reloadnav++
        },
        chooseLanguage(){
            return this.$i18n.locale
        }
    },
    computed:{
        isActive () {
            return this.otp.length === this.length
        },
    }
}
</script>